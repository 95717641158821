<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle>Добавить сотрудника в команду</VCardTitle>
          <VCardText>
            <VForm>
              <VTextField
                v-model="person.id"
                label="id сотрудника"
              />

              <VTextField
                v-model="person.role"
                label="Роль"
              />
            </VForm>
          </VCardText>

          <VCardActions class="px-4">
            <VBtn
              class="mr-3"
              depressed
              color="primary"
              @click="createLinkToTeam"
            >
              Добавить
            </VBtn>
            <VBtn
              text
              depressed
              @click="cancel"
            >
              Отмена
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'AddUserToTeamForm',

  data() {
    return {
      person: {
        id: '',
        role: '',
      },
    };
  },

  computed: {
    teamId() {
      return this.$route.params.teamId;
    },
  },

  methods: {
    createLinkToTeam() {
      const { person, teamId } = this;
      this.$di.api.Orgstructure
        .peopleCreateLinkToTeam({ person, teamId })
        .then(() => {
          this.$di.notify.snackSuccess('Сотрудник добавлен');
          this.cancel();
        })
        .catch(this.$di.notify.errorHandler);
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
